import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PasswordResetLink from "../components/PasswordResetLink"
import { isAuthenticated } from "../utils/Auth"
import { navigate } from "gatsby"

const PasswordResetLinkPage = () => {
	isAuthenticated() && navigate('/u/dashboard')

	return (
		<Layout>
			<SEO title="Lost Password Reset Link" />
			<PasswordResetLink />
		</Layout>
	)
}
export default PasswordResetLinkPage
