import React from "react"
// @material-ui/icons
import Lock from "@material-ui/icons/Lock"
// core components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Button from "../CustomButtons/Button.jsx"
import Card from "../Card/Card.jsx"
import CustomInput from "../CustomInput/CustomInput.jsx"
import InfoArea from "../InfoArea/InfoArea.jsx"
// styles
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import CardHeader from "../Card/CardHeader"
import axios from "axios"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
}

const baseUrl = `${process.env.GATSBY_API_URL}`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const PasswordResetLink = props => {
  const { classes } = props
  const [state, setState] = React.useState({})
  const [message, setMessage] = React.useState("")

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const params = "/user/lost-password?_format=json"
    const payload = {
      mail: state.email,
    }
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }

    axios
      .post(baseUrl + params, payload, config)
      .then(function(response) {
        // console.log(response)
        setMessage(response.data.message)
      })
      .catch(function(error) {
        console.log(error.response)
      })
  }

  return (
    <GridContainer
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ padding: "1.5rem 16px" }}
    >
      <Card style={style.cardBorder}>
        <CardHeader
          plain
          color="primary"
          className={`${classes.textCenter} ${classes.cardLoginHeader}`}
        >
          <h5 className={classes.cardTitleWhite}>
            Request a New Password Now!
          </h5>
        </CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <InfoArea
              className={classes.infoArea}
              title="Password Reset"
              description={
                <p>
                  We understand that from time to time we forget passwords. It
                  happens. Go ahead and use this form to get a new password sent
                  to you ;)
                </p>
              }
              icon={Lock}
              iconColor="info"
            />
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            {!message ? (
              <form
                name="contact"
                method="POST"
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <noscript>
                  <p>This form won’t work with Javascript disabled</p>
                </noscript>

                <CustomInput
                  labelText="Email"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    name: "email",
                    type: "email",
                    className: "form-control",
                    placeholder: "Email...",
                    autoFocus: false,
                  }}
                />

                <div className={classes.textCenter}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ padding: "10px 50px 20px", marginTop: "30px" }}
                  >
                    Request Temporary Password
                  </Button>
                </div>
              </form>
            ) : (
              <h5 style={{ padding: "50px" }}>{message}</h5>
            )}
          </GridItem>
        </GridContainer>
      </Card>
    </GridContainer>
  )
}

export default withStyles(javascriptStyles)(PasswordResetLink)
